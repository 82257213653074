import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column flex-lg-row" }
const _hoisted_2 = { class: "flex-lg-row-fluid me-lg-15 order-2 order-lg-1 mb-10 mb-lg-0" }
const _hoisted_3 = { class: "flex-column flex-lg-row-auto w-lg-250px w-xl-300px mb-10 order-1 order-lg-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Details = _resolveComponent("Details")!
  const _component_Events = _resolveComponent("Events")!
  const _component_Invoices = _resolveComponent("Invoices")!
  const _component_Summary = _resolveComponent("Summary")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Details),
      _createVNode(_component_Events),
      _createVNode(_component_Invoices)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Summary)
    ])
  ]))
}