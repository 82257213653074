import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "card card-flush mb-0",
  "data-kt-sticky": "true",
  "data-kt-sticky-name": "subscription-summary",
  "data-kt-sticky-offset": "{default: false, lg: '200px'}",
  "data-kt-sticky-width": "{lg: '250px', xl: '300px'}",
  "data-kt-sticky-left": "auto",
  "data-kt-sticky-top": "150px",
  "data-kt-sticky-animation": "false",
  "data-kt-sticky-zindex": "95"
}
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = {
  href: "#",
  class: "btn btn-sm btn-light btn-icon",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_5 = { class: "svg-icon svg-icon-3" }
const _hoisted_6 = { class: "card-body pt-0 fs-6" }
const _hoisted_7 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_UserMenu = _resolveComponent("UserMenu")!
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[0] || (_cache[0] = _createElementVNode("div", { class: "card-title" }, [
        _createElementVNode("h2", null, "Summary")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen052.svg" })
          ])
        ]),
        _createVNode(_component_UserMenu)
      ])
    ]),
    _createElementVNode("div", _hoisted_6, [
      _cache[2] || (_cache[2] = _createStaticVNode("<div class=\"mb-7\"><div class=\"d-flex align-items-center\"><div class=\"symbol symbol-60px symbol-circle me-3\"><img alt=\"Pic\" src=\"media/avatars/300-5.jpg\"></div><div class=\"d-flex flex-column\"><a href=\"#\" class=\"fs-4 fw-bolder text-gray-900 text-hover-primary me-2\">Sean Bean</a><a href=\"#\" class=\"fw-bold text-gray-600 text-hover-primary\">sean@dellito.com</a></div></div></div><div class=\"separator separator-dashed mb-7\"></div><div class=\"mb-7\"><h5 class=\"mb-4\">Product details</h5><div class=\"mb-0\"><span class=\"badge badge-light-info me-2\">Basic Bundle</span><span class=\"fw-bold text-gray-600\">$149.99 / Year</span></div></div><div class=\"separator separator-dashed mb-7\"></div><div class=\"mb-10\"><h5 class=\"mb-4\">Payment Details</h5><div class=\"mb-0\"><div class=\"fw-bold text-gray-600 d-flex align-items-center\"> Mastercard <img src=\"media/svg/card-logos/mastercard.svg\" class=\"w-35px ms-2\" alt=\"\"></div><div class=\"fw-bold text-gray-600\">Expires Dec 2024</div></div></div><div class=\"separator separator-dashed mb-7\"></div><div class=\"mb-10\"><h5 class=\"mb-4\">Subscription Details</h5><table class=\"table fs-6 fw-bold gs-0 gy-2 gx-2\"><tr class=\"\"><td class=\"text-gray-400\">Subscription ID:</td><td class=\"text-gray-800\">sub_4567_8765</td></tr><tr class=\"\"><td class=\"text-gray-400\">Started:</td><td class=\"text-gray-800\">15 Apr 2021</td></tr><tr class=\"\"><td class=\"text-gray-400\">Status:</td><td><span class=\"badge badge-light-success\">Active</span></td></tr><tr class=\"\"><td class=\"text-gray-400\">Next Invoice:</td><td class=\"text-gray-800\">15 Apr 2022</td></tr></table></div>", 7)),
      _createElementVNode("div", _hoisted_7, [
        _createVNode(_component_router_link, {
          to: "/subscriptions/add",
          class: "btn btn-primary",
          id: "kt_subscriptions_create_button"
        }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode(" Edit Subscription ")
          ])),
          _: 1
        })
      ])
    ])
  ]))
}