import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card card-flush pt-3 mb-5 mb-xl-10" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = { class: "card-toolbar" }
const _hoisted_4 = { class: "card-body pt-3" }
const _hoisted_5 = { class: "mb-10" }
const _hoisted_6 = { class: "d-flex flex-wrap py-5" }
const _hoisted_7 = { class: "flex-equal me-5" }
const _hoisted_8 = { class: "table fs-6 fw-bold gs-0 gy-2 gx-2 m-0" }
const _hoisted_9 = { class: "text-gray-800 min-w-200px" }
const _hoisted_10 = { class: "mb-0" }
const _hoisted_11 = { class: "table-responsive" }
const _hoisted_12 = { class: "table align-middle table-row-dashed fs-6 gy-4 mb-0" }
const _hoisted_13 = { class: "fw-bold text-gray-800" }
const _hoisted_14 = { class: "text-end" }
const _hoisted_15 = {
  href: "#",
  class: "btn btn-icon btn-active-light-primary w-30px h-30px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_16 = { class: "svg-icon svg-icon-3" }
const _hoisted_17 = { class: "text-end" }
const _hoisted_18 = {
  href: "#",
  class: "btn btn-icon btn-active-light-primary w-30px h-30px",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_19 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_UserMenu = _resolveComponent("UserMenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[1] || (_cache[1] = _createElementVNode("div", { class: "card-title" }, [
        _createElementVNode("h2", { class: "fw-bolder" }, "Product Details")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_router_link, {
          to: "/subscriptions/add",
          class: "btn btn-light-primary"
        }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Update Product")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _cache[8] || (_cache[8] = _createElementVNode("h5", { class: "mb-4" }, "Billing Address:", -1)),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("table", _hoisted_8, [
              _createElementVNode("tr", null, [
                _cache[3] || (_cache[3] = _createElementVNode("td", { class: "text-gray-400 min-w-175px w-175px" }, "Bill to:", -1)),
                _createElementVNode("td", _hoisted_9, [
                  _createVNode(_component_router_link, {
                    to: "/subscriptions/view",
                    class: "text-gray-800 text-hover-primary"
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" e.smith@kpmg.com.au")
                    ])),
                    _: 1
                  })
                ])
              ]),
              _cache[4] || (_cache[4] = _createElementVNode("tr", null, [
                _createElementVNode("td", { class: "text-gray-400" }, "Customer Name:"),
                _createElementVNode("td", { class: "text-gray-800" }, "Emma Smith")
              ], -1)),
              _cache[5] || (_cache[5] = _createElementVNode("tr", null, [
                _createElementVNode("td", { class: "text-gray-400" }, "Address:"),
                _createElementVNode("td", { class: "text-gray-800" }, " Floor 10, 101 Avenue of the Light Square, New York, NY, 10050. ")
              ], -1)),
              _cache[6] || (_cache[6] = _createElementVNode("tr", null, [
                _createElementVNode("td", { class: "text-gray-400" }, "Phone:"),
                _createElementVNode("td", { class: "text-gray-800" }, "(555) 555-1234")
              ], -1))
            ])
          ]),
          _cache[7] || (_cache[7] = _createStaticVNode("<div class=\"flex-equal\"><table class=\"table fs-6 fw-bold gs-0 gy-2 gx-2 m-0\"><tr><td class=\"text-gray-400 min-w-175px w-175px\"> Subscribed Product: </td><td class=\"text-gray-800 min-w-200px\"><a href=\"#\" class=\"text-gray-800 text-hover-primary\">Basic Bundle</a></td></tr><tr><td class=\"text-gray-400\">Subscription Fees:</td><td class=\"text-gray-800\">$149.99 / Year</td></tr><tr><td class=\"text-gray-400\">Billing method:</td><td class=\"text-gray-800\">Annually</td></tr><tr><td class=\"text-gray-400\">Currency:</td><td class=\"text-gray-800\">USD - US Dollar</td></tr></table></div>", 1))
        ])
      ]),
      _createElementVNode("div", _hoisted_10, [
        _cache[18] || (_cache[18] = _createElementVNode("h5", { class: "mb-4" }, "Subscribed Products:", -1)),
        _createElementVNode("div", _hoisted_11, [
          _createElementVNode("table", _hoisted_12, [
            _cache[17] || (_cache[17] = _createElementVNode("thead", null, [
              _createElementVNode("tr", { class: "border-bottom border-gray-200 text-start text-gray-400 fw-bolder fs-7 text-uppercase gs-0" }, [
                _createElementVNode("th", { class: "min-w-150px" }, "Product"),
                _createElementVNode("th", { class: "min-w-125px" }, "Subscription ID"),
                _createElementVNode("th", { class: "min-w-125px" }, "Qty"),
                _createElementVNode("th", { class: "min-w-125px" }, "Total"),
                _createElementVNode("th", { class: "text-end min-w-70px" }, "Actions")
              ])
            ], -1)),
            _createElementVNode("tbody", _hoisted_13, [
              _createElementVNode("tr", null, [
                _cache[9] || (_cache[9] = _createElementVNode("td", null, [
                  _createElementVNode("label", { class: "w-150px" }, "Basic Bundle"),
                  _createElementVNode("div", { class: "fw-normal text-gray-600" }, "Basic yearly bundle")
                ], -1)),
                _cache[10] || (_cache[10] = _createElementVNode("td", null, [
                  _createElementVNode("span", { class: "badge badge-light-danger" }, "sub_4567_8765")
                ], -1)),
                _cache[11] || (_cache[11] = _createElementVNode("td", null, "1", -1)),
                _cache[12] || (_cache[12] = _createElementVNode("td", null, "$149.99 / Year", -1)),
                _createElementVNode("td", _hoisted_14, [
                  _createElementVNode("a", _hoisted_15, [
                    _createElementVNode("span", _hoisted_16, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen019.svg" })
                    ])
                  ]),
                  _createVNode(_component_UserMenu)
                ])
              ]),
              _createElementVNode("tr", null, [
                _cache[13] || (_cache[13] = _createElementVNode("td", null, [
                  _createElementVNode("label", { class: "w-150px" }, "Pro Bundle"),
                  _createElementVNode("div", { class: "fw-normal text-gray-400" }, "Basic yearly bundle")
                ], -1)),
                _cache[14] || (_cache[14] = _createElementVNode("td", null, [
                  _createElementVNode("span", { class: "badge badge-light-danger" }, "sub_4567_3433")
                ], -1)),
                _cache[15] || (_cache[15] = _createElementVNode("td", null, "5", -1)),
                _cache[16] || (_cache[16] = _createElementVNode("td", null, "$949.99 / Year", -1)),
                _createElementVNode("td", _hoisted_17, [
                  _createElementVNode("a", _hoisted_18, [
                    _createElementVNode("span", _hoisted_19, [
                      _createVNode(_component_inline_svg, { src: "media/icons/duotune/general/gen019.svg" })
                    ])
                  ]),
                  _createVNode(_component_UserMenu)
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  ]))
}